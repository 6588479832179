import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button,  IconButton, Image, Stack, Switch, Text} from "@chakra-ui/react";
import Dropzone from '@components/dropzone/Dropzone';
import { toast } from "react-toastify";
import { useState } from "react";

export const UploadImageForm = ({ showPreview = true, image: defaulImage, title="Drag &  drop some images here"
    , maxSize = 5 * 1024 * 1024, types = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'image/svg',
    'image/gif',
    'image/webp',
    'image/bmp',
],
    accept = ['.jpg', '.jpeg', '.png', '.svg', '.gif', '.webp', '.bmp'],
    onSubmit = () => { } }) => {
    const [image, setImage] = useState(defaulImage)
    const [isThumbnail, setIsThumbnail] = useState(false)
    const [isHighQuality, setIsHighQuality] = useState(false)

    return (
        <Box maxW='sm' >
            {showPreview && <Box display={!!image?.imgSrc ? 'inherit' : 'none'} position={'relative'} width={'fit-content'}>
                <Image loading='lazy' src={image?.imgSrc} alt={image?.file?.name} />
                <IconButton colorScheme={'red'} position={'absolute'} top={-5} right={-5} onClick={() => setImage({})} icon={<DeleteIcon color={'white'} />} />
            </Box>}

            <Box p='2'>
                <Text>
                    {image?.file?.name}
                </Text>

                {image?.file?.size ? <Box>
                    {Math.round(((((image?.file?.size / 1024) / 1024) + Number.EPSILON)) * 100) / 100}
                    <Box as='span' fontSize='md'>
                        <small> Mbs</small>
                    </Box>
                    <Stack direction='row' spacing={2} my={3}>
                        <Switch isChecked={isThumbnail} onChange={() => {
                            setIsThumbnail(!isThumbnail)
                            setIsHighQuality(false)
                        }}>Thumbnail</Switch>
                        <Switch isChecked={isHighQuality} onChange={() => {
                            setIsHighQuality(!isHighQuality)
                            setIsThumbnail(false)
                        }}>High Quality</Switch>
                    </Stack>


                    <Button onClick={() => onSubmit(image,{
                        isThumbnail,
                        isHighQuality
                    })} colorScheme='whatsapp' size='sm'>Upload</Button>
                </Box> : <Dropzone
                    title={title}
                    onDrop={({ file, src }) => {
                        setImage({ imgSrc: src, file: file })
                    }}
                    onError={(error) => {
                        toast.error(error)
                    }}
                    maxSize={maxSize} types={types} accept={accept}>
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>{title}</p>
                            </div>
                        </section>
                    )}
                </Dropzone>}





            </Box>
        </Box>
    )
}