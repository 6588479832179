import {
  FETCH_PART_TYPES_SUCCESS,
  FETCH_PART_TYPES_STARTED,
  FETCH_PART_TYPES_FAILURE,
  FETCH_PARTS_SUCCESS,
  FETCH_PARTS_STARTED,
  FETCH_PARTS_FAILURE,
  FETCH_PART_SUCCESS,
  FETCH_PART_STARTED,
  FETCH_PART_FAILURE,
  SEARCH_PART_STARTED,
  SEARCH_PART_SUCCESS,
  SEARCH_PART_FAILURE,
  ADD_PART_SUCCESS,
  ADD_PART_FAILURE,
  UPDATE_PART_FAILURE,
  UPDATE_PART_SUCCESS,
  DELETE_PART_SUCCESS,
  DELETE_PART_FAILURE,
  RESET_PART_SELECTED,

} from "../actions/types";

const initialState = {
  types: [],
  parts: [],
  loading: false,
  selected: null,
  error: null
};

const partReducer = (state = initialState, action) => {
  switch (action.type) {
    //FETCH PART TYPES
    case FETCH_PART_TYPES_STARTED:
      return {
        ...state,
        loading: true,
        types: [],
      };
    case FETCH_PART_TYPES_SUCCESS:
      return {
        ...state,
        types: action.payload.data,
        loading: false,
        error: null
      };

    case FETCH_PART_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    //FETCH
    case FETCH_PARTS_STARTED:
      return {
        ...state,
        loading: true,
        parts: [],
        paginate: {}
      };
    case FETCH_PARTS_SUCCESS:
      return {
        ...state,
        parts: action.payload.parts,
        paginate: action.payload.paginate,
        loading: false,
        error: null
      };

    case FETCH_PARTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };


    //
    case FETCH_PART_STARTED:
      return {
        ...state,
        loading: true,
        selected: null,
      };

    case FETCH_PART_SUCCESS:
      return {
        ...state,
        selected: { ...action.payload.data.part},
        loading: false,
        error: null
      };

    case FETCH_PART_FAILURE:
      return {
        ...state,
        selected: null,
        loading: false,
        error: action.payload.error
      };


    //RESET
    case RESET_PART_SELECTED: {
      return {
        ...state,
        loading: false,
        selected: null,
      };
    }



    //SEARCH
    case SEARCH_PART_STARTED:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_PART_SUCCESS:

      return {
        ...state,
        parts: action.payload.parts,
        loading: false,
        error: null
      };

    case SEARCH_PART_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };

    //ADD
    case ADD_PART_SUCCESS:
      return {
        ...state,
        parts: [action.payload.part, ...state.parts],
        error: null,
        success: action.payload.successMessage
      };

    case ADD_PART_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //DELETE
    case DELETE_PART_SUCCESS:
      return {
        ...state,
        parts: state.parts.filter(part => part._id !== action.payload.id),
        error: null,
        success: action.payload.successMessage
      };




    case DELETE_PART_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };


    //UPDATE
    case UPDATE_PART_SUCCESS:
      return {
        ...state,
        error: null,
        success: action.payload.successMessage
      };


    case UPDATE_PART_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        success: null
      };

    default:
      return state;
  }
};

export default partReducer;
